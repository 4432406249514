<template>
    <footer class="Wrapper-footer Wrapper-section">
        <div class="FooterIntro">
            <div class="container">
                <h1>ChangeMakers. RainMakers. SalesMakers.</h1>
                <h2>Transforming today to keep you ahead of what’s coming next.<br /><span class="author">JFI</span></h2>
            </div>
        </div>
        <div class="FooterNavigation">
            <div class="FooterNavigationItem FooterNavigation-main">
                <header>Discover</header>
                <nav>
                    <router-link :key="index" :to="{ name: discover.name}" @click.native="activeLink" rel="noreferrer" v-for="(discover,index) in discovers">{{(discover.meta.shortLabel)?discover.meta.shortLabel:discover.meta.label}}</router-link>
                </nav>
            </div>
            <div class="FooterNavigationItem FooterNavigation-expertises">
                <header>Expertise</header>
                <nav>
                    <router-link :key="index" :to="{ name: expertise.name}" @click.native="activeLink" rel="noreferrer" v-for="(expertise,index) in expertises">{{(expertise.meta.shortLabel)?expertise.meta.shortLabel:expertise.meta.label}}</router-link>
                </nav>
            </div>
            <div class="FooterNavigationItem">
                <div class="FooterNavigation-clientStories">
                    <header>Client stories</header>
                    <nav>
                        <router-link :key="index" :to="{ name: 'ClientStories', params: { client: client.key }}" @click.native="activeLink" rel="noreferrer" v-for="(client,index) in clientStories">{{client.name}}</router-link>
                    </nav>
                </div>
                <div class="FooterNavigation-socials">
                    <header>Social</header>
                    <nav>
                        <a :aria-label="social.name" :href="social.url" :key="index" class="SocialsIcon-link" rel="noreferrer" target="_blank" v-for="(social,index) in socials">
                            <svg class="SocialsIcon-svg">
                                <use v-bind:xlink:href="`#symbol-${social.name}`"></use>
                            </svg>
                            <span>{{social.name}}</span>
                        </a>
                    </nav>
                </div>
            </div>
            <div class="FooterNavigationItem FooterContact">
                <header>Contact us</header>
                <nav>
                    <a :href="`mailto:${headquarter.email}`" rel="noreferrer">{{headquarter.email}}</a>
                    <a :href="headquarter.gmap" rel="noreferrer" target="_blank" v-html="headquarter.address"></a>
                </nav>
            </div>
        </div>
        <div class="FooterCopyright">
            <div class="container">
                <p>Jumping Fox Interactive ©{{year}} All rights reserved.</p>
            </div>
        </div>
    </footer>
</template>

<script>
    import store from '@/store';

    export default {
        name: 'Footer',
        data() {
            return {
                routes: this.$router.options.routes.filter(route => route.meta.footer),
                openClass: 'open',
                offices: store.state.offices,
                socials: store.state.socials,
                discovers: this.$router.options.routes.filter(route => route.meta.discover),
                expertises: this.$router.options.routes.filter(route => route.meta.expertise),
                ourClients: store.state.ourClients
            };
        },
        computed: {
            year() {
                const d = new Date();
                return d.getFullYear();
            },
            headquarter() {
                return this.offices.filter(office => office.headquarter)[0];
            },
            clientStories() {
                return this.ourClients.filter(client => client.story && client.story.comingSoon !== true).sort(function (a, b) {return a.story.order - b.story.order;});
            }
        },
        methods: {
            toggleNavigation: function () {
                this.$root.$emit('toggleNavigation');
            },
            closeNavigation: function () {
                this.$root.$emit('closeNavigation');
            },
            activeLink: async function (el) {
                this.$root.$emit('activeLink', el);
            }
        }
    };
</script>

<!--
<style lang="scss">
    @import "../scss/_Variables.scss";
    @import "../scss/_DebugVariables.scss";
    @import "../scss/_Fonts.scss";
    @import '../scss/_Footer.scss';
</style>
-->
